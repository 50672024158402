import React from 'react';
import useSubcategories from '../../hooks/useSubcategories';
import MultiSelectUserCreate from './MultiSelectUserCreate';

type SubcategorySelectProps = {
  label?: string;
  values?: string[];
  required?: boolean;
  loading?: boolean;
  onChange: (values: string[]) => any;
  fullWidth?: boolean;
};

export default function SubcategorySelect({ label, values, loading, onChange, ...restOfProps }: SubcategorySelectProps) {
  const { options, categoriesLoading } = useSubcategories();

  return (
    <MultiSelectUserCreate
      id="subcategories"
      label={label || 'Subcategory'}
      loading={loading || categoriesLoading}
      defaultOptions={options}
      selectedValues={values}
      onChange={(values) => onChange(values)}
      {...restOfProps}
    />
  );
}
