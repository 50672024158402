import { useQuery } from '@tanstack/react-query';
import { OrganizationFeatureFlagEnum } from '@equips/entities-schema';
import { getCategories } from '../../graphql/queries/categoryQueries';
import { useAuth } from '../auth/AuthContext';
import { internalUsers } from '../auth/roles';

const useSubcategories = () => {
  const { auth, determineFeatureFlagVisibility, userCan } = useAuth();

  const organizationIdForQuery =
    userCan(internalUsers) || !determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.Categories) ? undefined : auth?.organizationId;

  const { data, isLoading: categoriesLoading } = useQuery(['getSubCategories'], () =>
    getCategories({
      organizationId: organizationIdForQuery,
      showUsedCategoriesOnly: false,
      showUsedSubcategoriesOnly: false,
    }),
  );

  const categories = data?.data?.categories?.data || [];

  let options = categories.map((category) => ({ value: category?.metadata?.name || '', label: category?.metadata?.name || '' }));

  if (organizationIdForQuery) {
    options = categories?.flatMap(
      (category) =>
        category?.metadata?.subcategories?.map((subcategory) => ({
          value: subcategory?.metadata?.name || '',
          label: subcategory?.metadata?.name || '',
        })) || [],
    );
  }

  return { options, categoriesLoading };
};

export default useSubcategories;
