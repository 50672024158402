import React, { useState, useMemo, useEffect, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import AccountTree from '@mui/icons-material/AccountTree';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlined from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { Category, Maybe } from '@equips/entities-schema';
import { useTranslation } from 'react-i18next';
import FormGroup from '../Form/FormGroup';
import Label from '../Form/Label';
import Input from '../Form/Input';
import InputLoader from '../Form/InputLoader';
import Button from '../Buttons/Button';
import { useCategories } from '../../hooks/useCategories';
import Checkbox from '../Form/Checkbox';
import { ActionButtonProps, actionButtonTextColors } from '../Buttons/ActionButton';
import { useAuth } from '../../auth/AuthContext';

const indentClasses = ['', 'pl-8', 'pl-16', 'pl-24'];

type CategorySelectProps = {
  label?: string | React.ReactNode;
  required?: boolean;
  loading?: boolean;
  emptySelectionText?: string;
  selectedCategoryIds?: string | string[] | null;
  organizationId?: string;
  onChange: (value: string, values: string[], labels: string[]) => any;
  fullWidth?: boolean;
  labelHelper?: string;
  multiselect?: boolean;
  showAllCategories?: boolean;
  showSubcategories?: boolean;
  parentCategoryIds?: string | string[] | null;
};

type CategoryCardProps = {
  onClick?: () => any;
  onChange?: (selected: boolean) => any;
  label?: string | null;
  className?: string;
  selected?: boolean;
  expanded?: boolean;
  children?: JSX.Element[];
  actions?: ActionButtonProps[];
  showSubcategories?: boolean;
};

function CategoryCard({
  onClick,
  onChange,
  label,
  className = '',
  selected = false,
  expanded = false,
  showSubcategories = true,
  children,
  actions,
  ...restOfProps
}: CategoryCardProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(expanded);
  }, [expanded]);

  return (
    <>
      <button
        title={t('select')}
        type="button"
        onClick={() => onClick?.()}
        className={`flex w-full items-center rounded-lg border border-gray-400 px-4 py-2 text-left hover:bg-gray-200 ${className}`}
        {...restOfProps}
      >
        <>
          {onChange && (
            <Checkbox
              id="multiselect"
              checked={selected}
              onChange={(e) => {
                e.stopPropagation();
                onChange?.(e.currentTarget.checked || false);
              }}
            />
          )}
          <div>
            <div>{label}</div>
          </div>
          {(actions || []).map(({ text, Icon, color, onClick }) => (
            <IconButton
              key={`categoryCardAction-${text}`}
              title={text}
              className={`${color ? actionButtonTextColors[color] : actionButtonTextColors['blue']}`}
              onClick={(e) => {
                e.stopPropagation();
                onClick?.(e);
              }}
              size="large"
            >
              <Icon />
            </IconButton>
          ))}
          {showSubcategories && onClick && children && children.length > 0 && (
            <IconButton
              title={isOpen ? t('collapse') : t('expand')}
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
              size="large"
            >
              {isOpen ? <IndeterminateCheckBoxOutlined /> : <AddBoxOutlined />}
            </IconButton>
          )}
        </>
      </button>
      {isOpen && children}
    </>
  );
}

export default function CategorySelect({
  label,
  required = false,
  loading = false,
  emptySelectionText = 'Any',
  selectedCategoryIds = null,
  organizationId = '',
  onChange,
  fullWidth = false,
  labelHelper = '',
  multiselect = false,
  showAllCategories = false,
  showSubcategories = true,
  parentCategoryIds,
}: CategorySelectProps) {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const organizationIdForQuery = showAllCategories ? undefined : organizationId || auth?.organizationId || null;
  const { loading: categoriesLoading, categories: allCategories } = useCategories(organizationIdForQuery);
  const [categoryIds, setCategoryIds] = useState<Set<string>>();
  const [q, setQ] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (selectedCategoryIds) {
      setCategoryIds(new Set(Array.isArray(selectedCategoryIds) ? selectedCategoryIds : selectedCategoryIds ? [selectedCategoryIds] : []));
    }
  }, [selectedCategoryIds]);

  const findCategories = useCallback((categories: Maybe<Category>[], categoryIds?: Set<string>): Maybe<Category>[] => {
    const foundCategories: Maybe<Category>[] = [];
    const leftToFind = new Set(categoryIds);

    for (const category of categories) {
      const { categoryId } = category || {};
      if (categoryId && leftToFind?.has(categoryId)) {
        foundCategories.push(category);
        leftToFind.delete(categoryId);
      }

      if (leftToFind.size < 1) {
        return foundCategories;
      }

      const subcategories = category?.metadata?.subcategories;
      if (subcategories) {
        const result = findCategories(subcategories, leftToFind);
        if (result) {
          foundCategories.push(...result);
        }
      }
    }

    return foundCategories;
  }, []);

  const categories = useMemo(() => {
    if (parentCategoryIds) {
      const foundCategories = findCategories(allCategories, new Set(parentCategoryIds));
      if (foundCategories.length > 0) {
        return foundCategories.reduce((acc, cur) => [...acc, ...(cur?.metadata?.subcategories || [])], []);
      }
    }

    return allCategories;
  }, [allCategories, parentCategoryIds, findCategories]);

  const selectedCategoryNames = useMemo(() => {
    const foundCategories = findCategories(categories, categoryIds);
    return foundCategories.map((x) => x?.metadata?.name || '');
  }, [categories, categoryIds, findCategories]);

  const handleSelect = () => {
    const categoryIdsArray = categoryIds ? [...categoryIds] : [];
    onChange(categoryIdsArray[0], categoryIdsArray, selectedCategoryNames);
    setOpen(false);
  };

  const filteredCategories = categories
    .filter((category): category is Category => {
      if (!category) return false;
      const { metadata } = category;
      const subcategoryNames = metadata?.subcategories?.map((s) => s?.metadata?.name) ?? [];
      const searchable = [metadata?.name, metadata?.shortId, ...subcategoryNames].join(' ');
      return !!searchable.match(new RegExp(q ?? '', 'gi'));
    })
    .sort((a, b) => a?.metadata?.name?.localeCompare(b?.metadata?.name ?? '') ?? 0);

  const selectItem = (selected: boolean, categoryId: string | null) => {
    if (!categoryId) return;
    if (!multiselect) {
      const newCategoryIds = new Set([categoryId]);
      const newCategoryNames = findCategories(categories, newCategoryIds).map((x) => x?.metadata?.name || '');
      setCategoryIds(newCategoryIds);
      onChange(categoryId, [categoryId], newCategoryNames);
      setOpen(false);
    } else if (selected) {
      setCategoryIds((prev) => new Set(prev ? prev.add(categoryId) : [categoryId]));
    } else {
      setCategoryIds((prev) => {
        const newIds = new Set(prev);
        newIds.delete(categoryId);
        return newIds;
      });
    }
  };

  const createTree = (category: Category, level: number) => {
    const categoryId = category?.categoryId || '';
    const subcategories = category?.metadata?.subcategories;
    let subcategoryIds = subcategories?.map((x) => x?.categoryId || '') || [];
    return {
      element: (
        <CategoryCard
          className={`rounded-none border-x-0 border-b-0 ${indentClasses[level]}`}
          label={category?.metadata?.name}
          expanded={!!q}
          selected={categoryIds?.has(categoryId)}
          onClick={() => selectItem(!categoryIds?.has(categoryId), categoryId)}
          onChange={multiselect ? (selected) => selectItem(selected, categoryId) : undefined}
          actions={
            multiselect && subcategoryIds.length > 0
              ? [
                  {
                    text: t('selectAll'),
                    Icon: AccountTree,
                    onClick: () => setCategoryIds((prev) => new Set([...(prev || []), categoryId, ...subcategoryIds])),
                  },
                ]
              : []
          }
        >
          {showSubcategories
            ? subcategories?.map((subcategory: Category) => {
                const { element, subcategoryIds: childSubcategoryIds } = createTree(subcategory, level + 1);
                subcategoryIds = [...subcategoryIds, ...childSubcategoryIds];
                return <div key={subcategory?.categoryId}>{element}</div>;
              })
            : undefined}
        </CategoryCard>
      ),
      subcategoryIds,
    };
  };

  const allCategoryIds: string[] = [];

  const ABBREVIATION_LIMIT = 8;
  const selectionText =
    selectedCategoryNames.length > 0
      ? selectedCategoryNames.length > ABBREVIATION_LIMIT
        ? `${selectedCategoryNames.slice(0, ABBREVIATION_LIMIT - 1).join(', ')} & ${selectedCategoryNames.length - ABBREVIATION_LIMIT} more`
        : selectedCategoryNames.join(', ')
      : emptySelectionText;

  return (
    <>
      <FormGroup fullWidth={fullWidth}>
        <Label id="selected" label={label || t('category')} required={required} helper={labelHelper} />
        {loading || categoriesLoading ? (
          <InputLoader fullWidth />
        ) : (
          <button
            id="selected"
            className="w-full rounded border border-gray-300 bg-white p-2 text-left text-gray-900 hover:border-gray-400"
            type="button"
            onClick={() => setOpen(true)}
          >
            {selectionText}
          </button>
        )}
      </FormGroup>

      <Dialog onClose={() => setOpen(false)} aria-labelledby="dialogTitle" open={open} fullWidth maxWidth="sm">
        <div className="flex justify-between">
          <DialogTitle id="dialogTitle">{label}</DialogTitle>
          {multiselect && (
            <div className="p-3">
              <Button onClick={handleSelect} blue tiny type="button">
                {t('apply')}
              </Button>
            </div>
          )}
        </div>
        <div className="flex p-3">
          <Input
            autoFocus
            className="no-drag-zone"
            id="searchQueryForCategories"
            value={q}
            onChange={(event) => setQ(event.target.value)}
            placeholder={t('search')}
          />
          {q && (
            <button className="ml-2 text-sm text-gray-700" type="button" onClick={() => setQ('')}>
              {t('clear')}
            </button>
          )}
        </div>
        <div className="h-64 overflow-scroll bg-gray-50">
          {!required && emptySelectionText && (
            <CategoryCard
              className="rounded-none border-x-0 border-b-0"
              data-testid="categoryDialogSelectAny"
              label={emptySelectionText}
              onClick={() => setCategoryIds(new Set())}
            />
          )}
          {multiselect && (
            <CategoryCard
              className="rounded-none border-x-0 border-b-0"
              data-testid="categoryDialogSelectAll"
              label={t('selectAll')}
              onClick={() => setCategoryIds(new Set(allCategoryIds))}
            />
          )}

          {filteredCategories.map((category) => {
            const { element, subcategoryIds } = createTree(category, 0);
            if (category?.categoryId) {
              allCategoryIds.push(category.categoryId);
            }
            allCategoryIds.push(...subcategoryIds);
            return <div key={category?.categoryId}>{element}</div>;
          })}
        </div>
      </Dialog>
    </>
  );
}
